
import { Component, Vue } from 'vue-property-decorator';
import MaxWidth from '@/components/MaxWidth.vue';
import { RouteNames } from '@/constants';
import { EnumsModule } from '@/store/modules/enums.module';
import { userHasRole } from '@/utils/roles';

type AdminDashboardLink = {
  text: string;
  route: { name: string };
};

@Component({
  components: {
    MaxWidth,
  },
})
export default class AdminDashboardView extends Vue {
  get links(): AdminDashboardLink[] {
    const list: AdminDashboardLink[] = [];

    if (userHasRole(EnumsModule.enums.role.FEATUREADMIN.id)) {
      list.push({
        text: 'Feature Flags',
        route: {
          name: RouteNames.AdminFeatureFlags,
        },
      });
    }

    if (userHasRole(EnumsModule.enums.role.LISTINGADMIN.id)) {
      list.push({
        text: 'Listing Types',
        route: {
          name: RouteNames.AdminListingTypes,
        },
      });
    }

    if (userHasRole(EnumsModule.enums.role.APPLICATIONTEAMROLEADMIN.id)) {
      list.push({
        text: 'Application Team Roles',
        route: {
          name: RouteNames.AdminApplicationTeamRoles,
        },
      });
    }

    if (userHasRole(EnumsModule.enums.role.LISTINGTYPEBUSINESSUNITAPPROVERADMIN.id)) {
      list.push({
        text: 'Listing Type Approver Administration',
        route: {
          name: RouteNames.AdminListingTypeBusinessUnitApprovers,
        },
      });
    }

    if (userHasRole(EnumsModule.enums.role?.SERVICEPROJECTMOVEMENTADMIN?.id)) {
       list.push({
        text: 'Service Project Movement',
        route: {
          name: RouteNames.AdminServiceProjectMovement,
        },
      }); 
    }

    if (userHasRole(EnumsModule.enums.role.SERVICEMANAGEMENTADMIN.id)) {
      list.push({
        text: 'Service Management',
        route: {
          name: RouteNames.AdminServiceManagement,
        },
      });

      list.push({
        text: 'Two-Otters Locking Statuses',
        route: {
          name: RouteNames.AdminTOLockingStatuses,
        },
      });
    }

    if (userHasRole(EnumsModule.enums.role.ORCHESTRATORADMIN.id)) {
      list.push({
        text: 'Orchestrator Monitor',
        route: {
          name: RouteNames.OrchestratorMonitor,
        },
      });
    }

    list.push({
      text: 'Registration Form Testing',
      route: {
        name: RouteNames.AdminRegistrationFormTesting,
      },
    });

    list.push({
      text: 'Kong Monitoring Panel',
      route: {
        name: RouteNames.KongMonitoringPanel,
      },
    });

    if (userHasRole(EnumsModule.enums.role.CROSSCHARGINGADMIN?.id)) {
      list.push({
        text: 'Cross-charging reporting',
        route: {
          name: RouteNames.CrossChargingReporting,
        },
      });
    }

    return list;
  }
}
